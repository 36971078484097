@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";
.gpo-tooltip {
  position: absolute;
  top: -6.2rem;
  left: -1.6rem;
  width: 18rem;
  cursor: default;
  &__container {
    background-color: $black;
    padding: 0.75rem;
    border-radius: .5rem;
    .row {
      @include grid(6rem 1fr, center, 0);
      span {
        &.title {
          color: $bot-color;
          flex: 0.5;
          font: {
            size: 0.7rem;
          }
        }
        &.status {
          border-radius: 2rem;
          border: 0.09rem solid $grey-200;
          padding: 0.2rem 0.5rem;
          display: flex;
          width: fit-content;
          font: {
            size: 0.8rem;
          }
          &--excellent {
            border-color: $green-500;
          }
          &--poor {
            border-color: $orange-300;
          }
          &--fair {
            border-color: darken($grey-300,5%);
          }
        }
        &.view {
          @include flex(row, flex-start, center);
          flex: 1;
          cursor: pointer;
          font: {
            size: 0.85rem;
          }
          img {
            width: 0.7rem !important;
            margin-right: 0.3rem;
          }
        }
        &.pages {
          flex: 1;
          font: {
            size: 0.85rem;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
  &:after {
    content: "";
    width: 1rem;
    height: 1rem;
    border-top: solid 1rem $black;
    border-left: solid 1rem transparent;
    border-right: solid 1rem transparent;
    top: 1rem;
    position: relative;
    left: 1rem;
  }
}
