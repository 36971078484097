@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-pdf-viewer {
  // width: 650px;
  position: relative;
  align-self: flex-start;
  .loader-wrapper {
    position: absolute;
    width:99%;
    height: calc(100vh - 10rem);
  }
  .doc{
    margin-top: 1rem;
    border:0.05rem solid #C4C4CD;
    height: calc(100vh - 8rem);
  }
  .gpo-pdf-header {
    background-color: $grey-600;
    color: $white;
    padding: 0.5rem;
    @include flex(row, space-between, center);
    span {
      @include flex(row, center, center);
      cursor: pointer;
      font: {
        size: 0.8rem;
      }
      img {
        margin-right: 0.3rem;
        @include square(0.7rem);
      }
    }
    .close_btn{
      margin-left:auto;
    }
  }
  .react-pdf__Document {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 9rem);
    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
  }
  canvas {
    width: 100% !important;
  }
}
.fullscreen-modal {
  z-index:10;
  position: fixed;
  left: 0;
  top: 0;
  @include square(100%);
  background-color: $white;
  header {
    @include flex(row, space-between, center);
    background-color: $grey-600;
    padding: 1rem;
    color: $white;
    img {
      cursor: pointer;
    }
  }
  &__body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 3rem);
    @include scrollbars(0.6rem, darken($grey-600, 5%), $grey-450);
    .rpv-core__inner-pages {
      @include scrollbars(0.6rem, darken($grey-600, 5%), $grey-450);
    }
  }
}
