@import "/src/assets/scss/variables";
@import "/src/assets/scss/mixins";

.gpo-custom-bot {
  .gpo-custom-chat-messages {
    max-height: calc(100vh - 12rem);
    overflow-y: auto;
    @include scrollbars(0.3rem, lighten($grey-450, 15%), $grey-300);
    margin-bottom: 0.5rem;
    .gpo-chat-wrapper {
      &__list {
        position: relative;
        padding: 0 1rem;
        .circle-spinner {
          left:35vw;
          height: 2em;
          width: 2em;
          margin: auto;
          position:relative;
          .circle {
          width: 100%;
          height: 100%;;
          border: .25rem dotted #000;
          border-radius: 50%;
          }
          .spinner {
          width: 100%;
          height: 100%;
          position: absolute;
          border-right: .25rem solid rgba(#fff, 0.5);
          border-top: .25rem dotted transparent;
          border-left: .25rem dotted transparent;
          border-bottom: .25rem dotted transparent;
          border-radius: 50%;
          transform: rotate(0deg);
          animation: spin 1s infinite linear;
          }
          }
        .agent-wrapper {
          margin: 3rem 0 1.5rem;    
            @keyframes spin {
            from {
            transform: rotate(0deg);
            }
            to {
            transform: rotate(360deg);
            }
            }
        }
        .agent-wrapper,
        .agent-logout-wrapper {
          @include flex(row, center, center);
          &__container {
            @include flex(column, center, center);
            min-width: 22rem;
            background-color: $white;
            padding: 1.5rem 0 1rem;
            border-radius: 1rem;
            border: 0.06rem solid $grey-300;
            position: relative;
            img {
              position: absolute;
              border-radius: 50%;
              top: -1.25rem;
              @include square(2.2rem);
            }
            span {
              font-size: 0.8rem;
              line-height: 1rem;
              &:last-child {
                font: {
                  size: 0.65rem;
                }
                color: lighten($grey-450, 30%);
              }
            }
          }
        }
        .agent-logout-wrapper {
          margin: 1.5rem 0;
          &__container {
            background-color: transparent;
            border: none;
            img {
              position: relative;
              @include square(0.85rem);
              top: auto;
              margin-right: 0.35rem;
            }
            span {
              strong {
                margin-right: 0.3rem;
              }
              &:first-child {
                @include flex(row, space-between, center);
                margin-bottom: 1rem;
              }
            }
            .action {
              @include flex(row, space-between, center);
              font: {
                size: 0.8rem;
              }
              span {
                margin: 0;
                button {
                  background-color: transparent;
                  outline: none;
                  border: 0.06rem solid lighten($grey-450, 20%);
                  border-radius: 1rem;
                  padding: 0.35rem 1.2rem 0.3rem 1.2rem;
                  text-transform: uppercase;
                  cursor: pointer;
                  transition: all 0.2s ease;
                  font: {
                    size: 0.6rem;
                    weight: 600;
                  }
                  &:first-child {
                    margin-left: 1.5rem;
                    margin-right: 0.3rem;
                  }
                  &:hover,
                  &:focus {
                    background-color: $blue-450;
                    border-color: $blue-450;
                    color: $white;
                  }
                }
              }
            }
          }
        }
        .response-count{
          @include flex(row, flex-start, center);
          width: 100%;
          &__container {
            margin-top:-0.5rem;
            span{
              color: $bot-bg;
              font-size: 0.8rem;
              font-weight: bold;
            }
          }
        }
        .agent-confirm {
          @include flex(row, flex-start, center);
          width: 100%;
          margin-top: 0.5rem;
          &__container {
            button {
              background-color: transparent;
              outline: none;
              border: 0.06rem solid lighten($grey-450, 20%);
              border-radius: 1rem;
              padding: 0.35rem 1.2rem 0.3rem 1.2rem;
              text-transform: uppercase;
              cursor: pointer;
              transition: all 0.2s ease;
              font: {
                size: 0.6rem;
                weight: 600;
              }
              &:first-child {
                margin-right: 0.3rem;
              }
              &:hover,
              &:focus {
                background-color: $blue-450;
                border-color: $blue-450;
                color: $white;
              }
            }
          }
        }
        .gpo-bot {
          .gpo-chats {
            @include flex(row, flex-start, flex-start);
            gap: 0.5rem;
            p {
              background-color: $bot-bg;
              border-top-left-radius: 0.3rem !important;
              color: $white;
              &.selectedSearch{
                border:0.2rem solid $yellow-400 !important;
              }
            }
          }
        }
        .gpo-user {
          @include flex(row, flex-end, center);
          p {
            background-color: $white;
            border-top-right-radius: 0.3rem !important;
            color: $app-bg;
            box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.07);
          }
        }
        .gpo-bot,
        .gpo-user {
          color: $white;
          position: relative;

          p {
            max-width: 30rem;
            border-radius: 1rem;
            padding: 0.75rem 1.5rem;
            line-height: 1.2rem;
            font: {
              size: 0.8rem;
            }
            &.selectedSearch{
              border:0.2rem solid $yellow-400 !important;
            }
          }
          .gpo-bot-avatar {
            @include flex(row, flex-start, center);
            margin-bottom: 0.75rem;
            img {
              @include square(2.5rem);
              margin-right: 0.5rem;
            }
            span {
              color: $app-bg;
              @include flex(column, flex-start, flex-start);
              gap: 0.15rem;
              font: {
                weight: 600;
                size: 0.75rem;
              }
              .gpo-time {
                font: {
                  size: 0.65rem;
                  weight: 300;
                }
              }
            }
          }
          .gpo-agent-avatar {
            @include flex(row, flex-start, center);
            margin-bottom: 0.75rem;
            img {
              //position: absolute;
              margin-right: 0.5rem;
              border-radius: 50%;
              top: -1.25rem;
              @include square(2.2rem);
            }
            span {
              color: $app-bg;
              @include flex(column, flex-start, flex-start);
              gap: 0.15rem;
              font: {
                weight: 600;
                size: 0.75rem;
              }
              .gpo-time {
                font: {
                  size: 0.65rem;
                  weight: 300;
                }
              }
            }
          }
          .gpo-user-avatar {
            @include flex(row, flex-end, center);
            margin-bottom: 0.75rem;
            span {
              color: $app-bg;
              @include flex(column, flex-end, flex-end);
              gap: 0.15rem;
              text-align: right;
              font: {
                weight: 600;
                size: 0.75rem;
              }
              .gpo-time {
                font: {
                  size: 0.65rem;
                  weight: 300;
                }
              }
            }
          }
          .gpo-chats {
            .gpo-message-section {
              &__top {
                @include flex(row, flex-start, flex-start);
                gap: 0.5rem;
                .user-action {
                  button {
                    border: none;
                    outline: none;
                    background: transparent;
                    cursor: pointer;
                    padding: 0 0.2rem;
                    &:last-child {
                      transform: rotate(180deg);
                    }
                    img {
                      @include square(1.1rem);
                    }
                  }
                }
              }
              &__chat-footer {
                margin-top: 0.5rem;
                @include flex(row, flex-end, center);
                .gpo-pdf-action {
                  position: relative;
                  .gpo-icon {
                    display: flex;
                    right: 4.5rem;
                    cursor: pointer;
                    position: relative;
                    img {
                      @include square(0.85rem);
                    }
                  }
                  .gpo-pop-up {
                    position: absolute;
                    top: -2rem;
                  }
                }
              }
              .gpo-no-data {
                margin-bottom: 0.75rem;
                .gpo-loading-data {
                  @include flex(row, center, flex-start);
                  width: min-content;
                  border-radius: 1rem;
                  padding: 0.75rem 1.5rem;
                  line-height: 1.2rem;
                  min-height: 2.75rem;
                  background-color: $bot-bg;
                  border-top-left-radius: 0.3rem !important;
                  & > div {
                    @include square(0.35rem);
                    margin: 0 0.2rem;
                    background-color: $white;
                    animation: animation .6s infinite alternate;
                  }
                  & > div:nth-child(2) {
                    animation-delay: 0.2s;
                  }
                  & > div:nth-child(3) {
                    animation-delay: 0.4s;
                  }
                  @keyframes animation {
                    0%	{
                      transform: translateY(15px)
                    }
                    30%{
                      transform: translateY(15px);
                    }
                    60%{
                      transform: translateY(15px)
                    }
                    100%{
                      transform: translateY(0) scaleX(.5)
                    }
                  }
                }
              }
            }
          }
        }
        &__reaction {
          position: absolute;
        }
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        &:last-child {
          margin-bottom: 1.5rem;
        }
        &:first-child {
          .chats {
            padding-top: 5rem;
          }
        }
      }
    }
  }
  .gpo-chat-send {
    @include flex(row, space-between, center);
    gap: 1rem;
    &__field {
      background-color: $white !important;
      border: 0.06rem solid $white !important;
      border-radius: 1rem;
      min-height: 50px !important;
      display: flex;
      flex: 1;
      &.disabled{
        background-color: $disabled!important;
        border-color:$disabled !important;
      }
      form {
        @include flex(row, space-between, center);
        flex: 1;
        input[type="text"] {
          color: $app-bg !important;
          flex: 1;
          background-color: transparent;
          outline: none;
          border: none;
          padding: 0 1rem;
          font: {
            family: var(--primary-font);
            weight: 300;
            size: 0.9rem;
          }
          &::placeholder {
            color: $grey-450;
            font-size: 0.85rem;
          }
        }
        button {
          background: transparent;
          border: none;
          position: relative;
          display: flex;
          &:after {
            content: "";
            @include square(2.2rem);
            background-color: darken($bg-color, 5%);
            position: absolute;
            border-radius: 50%;
            top: -0.56rem;
            left: 0.6rem;
          }
          svg {
            @include square(0.9rem);
            margin: 0 1rem;
            z-index: 1;
            path {
              fill: $app-bg;
            }
          }
        }
      }
    }
    &__start {
      @include flex(column, center, center);
      img {
        @include square(0.9rem);
        margin-bottom: 0.3rem;
        cursor: pointer;
      }
      span {
        color: $grey-300;
        font: {
          size: 0.8rem;
        }
      }
    }
  }
}
